import './before_after_slider.scss';
import Before_After_Slider from 'src/classes/Before_After_Slider';


import './carousel_support';

import { on_ready } from 'src/js/utils';

on_ready(()=>{
    document.querySelectorAll('.ff_before_after_slider').forEach(el=>{
        if( typeof el.before_after_slider !== 'undefined' ) return;
        el.before_after_slider = new Before_After_Slider(el);
    })
});

